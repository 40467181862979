/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
import {toast} from "vue3-toastify";
import {router} from "@inertiajs/vue3";

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(
    (response) => {
        if (response.data.message && response.data.status_code && response.data.status_code !== 200) {
            toast.error(response.data.message, {autoClose: 2000, position: toast.POSITION.TOP_RIGHT});
        } else if (response.data.message) {
            toast.success(response.data.message, {autoClose: 2000, position: toast.POSITION.TOP_RIGHT});
        }
        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.data.message) {
                toast.error(error.response.data.message, {autoClose: 2000, position: toast.POSITION.TOP_RIGHT});
            } else {
                toast.error(error.response.data, {autoClose: 2000, position: toast.POSITION.TOP_RIGHT});
            }
            if (error.response.status === 401) {
                //TODO change to login modal
                router.visit('/login');
            }
        } else if (error.request && error.code !== 'ERR_CANCELED') {
            toast.error('Network error. Please check your connection.', {autoClose: 2000, position: toast.POSITION.TOP_RIGHT});
        } else if (error.code !== 'ERR_CANCELED') {
            toast.error('An error occurred');
        }

        return Promise.reject(error);
    }
);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'us3',
    wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
    wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});
